<template>

    <div>
        <Header />

        <SystemMessage />

        <main class="root-element">
            <Breadcrumbs />
            <PageTitle />

            <div class="root-element body-content" v-if="emailConfirmedOK">

                <div class="columns">
                    <div class="content">
                        <h1>{{text.title_confirm_email}}</h1>
                        <p v-html="text.body_confirm_email"></p>
                        <a :href="returnURL" :title="text.login" class="btn" v-if="returnURL">{{text.login}}</a>
                        <router-link :to="{ name: 'Login' }" :title="text.login" class="btn" v-if="!returnURL">{{text.login}}</router-link>
                    </div>
                </div>

            </div>
        </main>

        <Footer />
    </div>

</template>

<script>
    import {confirmUserEmailByID} from "@/shared/dataservice";
    import {MESSAGE_TYPE_ERROR} from "@/shared/consts";
    import {log} from "@/shared/utils";

    import lang from "@/shared/lang";
    import Breadcrumbs from "@/components/breadcrumbs";
    import PageTitle from "@/components/pagetitle";
    import Header from '@/components/header';
    import Footer from '@/components/footer';
    import SystemMessage from '@/components/systemmessage';

    export default {
        name: 'Maintenance',
        components: {Breadcrumbs, PageTitle, Header, Footer, SystemMessage},
        data() {
            return {
                emailConfirmedOK: true,
            }
        },
        computed: {
            text() {
                return lang;
            },
            userID() {
                return this.$route.query.userId;
            },
            token() {
                return this.$route.query.token;
            },
            returnURL() {
                return this.$route.query.returnUrl ? this.$route.query.returnUrl : false;
            }
        },
        methods: {
            async confirmEmail(userID, token) {
                log(["Trigger confirm email", userID, token, { token : token}]);

                let result = await confirmUserEmailByID(userID, { token : token});

                if(result.status !== 204){
                    this.emailConfirmedOK = false;

                    this.$store.dispatch('addSystemMessageAction', {
                        'type' : MESSAGE_TYPE_ERROR,
                        'message' : lang.message_email_confirmation_error
                    });
                }
            }
        },
        async mounted() {
            this.confirmEmail(this.userID, this.token);
        },
    }
</script>

<style lang="scss" scoped>


</style>
